import React from 'react';
import { useContext } from 'react';
import "./home.css";
import AutoCarousel from '../autoCarousel/AutoCarousel';
import Carousel from '../carousel/Carousel';
import { HashLink } from 'react-router-hash-link';
import { IdiomaContext } from "../../context/IdiomaContext";
import EndNavBar from '../endNavBar/EndNavBar';

const Home = () => {
    const {isInglish} = useContext(IdiomaContext);
       
    return ( 
        <div className='contSeccion seccionHome' secciontitle="Home">
            {/* Encabezado */}
            <div className='contHome OoS' id="home">
                <AutoCarousel/>   
                <div className='contColorHome flex'> 
                    {/* <h2 className='tituloHome bienVenidos'>{(isInglish && "Welcome to") || (!isInglish  && "Bienvenidos a")}</h2> */}
                    <img className='logoHome2' src="/images/logo4.png" alt="Logo Little Creek" />
                    {/* <h3 className='bilingual'>Bilingual School</h3> */}
                    <div className='homeFilter'></div>
                    {/* <img className='animales pajaro OoS' src="/images/pajaro.png" alt="pajaro" /> */}
                </div>
            </div>

            {/* Texto Principal */}
            <div className='flex column'>
                <div className='contTextoPcipalHome flex column'>
                    <div className='contTextoPcipalHome2 OoS'>
                        {
                            (isInglish &&
                                <>  We are a registered nature-based school inspired by the <a href="https://www.reggiochildren.it/en/reggio-emilia-approach/valori-en/" target="_blank" className='linkAdmis' rel="noreferrer">Reggio Emilia philosophy</a>, serving children ages 2 to 8. Located in rural Coos Bay, nestled in a beautiful forest and surrounded by creeks, we found our inspiration for the name Little Creek.<br /><br />
                                    Little Creek Bilingual School was born from our family's dream of creating a place where our son is valued for who he is and his potential to learn. A school where he can explore, play, create, and investigate in his own time and through his own unique ways of expression. We aim to share this space with families who share this same vision of education for their children.<br /><br />
                                    <span className='titulosTextoPcipal'>Vision</span><br /><br />
                                    Our vision is to create a unique school in our community that promotes bilingual and multicultural learning, with a nature-based approach that honors traditional ecological knowledge. This is delivered through an emergent, holistic curriculum using creative languages.<br /><br />
                                    <span className='titulosTextoPcipal'>Mission</span><br /><br />
                                    Our mission is to raise awareness in a community that views the child as a whole and competent being, the protagonist of their own learning. We aim to create a place where education is inspired by family cultures and their diversity, where we connect with and respect the environment and the place we live, fostering positive change in our community and the world.<br /><br />
                                </>
                            )
                            ||
                            (
                                !isInglish &&
                                <>  Somos una escuela registrada basada en la naturaleza e inspirada en la <a href="https://www.reggiochildren.it/en/reggio-emilia-approach/valori-en/" target="_blank" className='linkAdmis' rel="noreferrer">Filosofía Reggio Emilia</a>, que atiende a niños de 2 a 8 años. Estamos ubicados en una zona rural de Coos Bay, en medio de un hermoso bosque y rodeados de arroyos, lo que nos inspiró a nombrar nuestra escuela Little Creek.<br /><br />
                                    Little Creek Bilingual School nació del sueño de nuestra familia de crear un lugar donde nuestro hijo sea valorado por quien es y por su potencial para aprender. Una escuela donde pueda explorar, jugar, crear e investigar a su propio ritmo y a través de sus propios lenguajes. Queremos compartir este espacio con familias que comparten esta misma visión de la educación para sus hijos.<br /><br />
                                    <span className='titulosTextoPcipal'>Visión</span><br /><br />
                                    Nuestra visión es crear una escuela única en nuestra comunidad que promueva el aprendizaje bilingüe y multicultural, con un enfoque basado en la naturaleza que reconozca el conocimiento ecológico tradicional. Todo esto se imparte a través de un currículo emergente y holístico utilizando lenguajes creativos.<br /><br />
                                    <span className='titulosTextoPcipal'>Misión</span><br /><br />
                                    Nuestra misión es generar conciencia en una comunidad que considera al niño como un ser completo y competente, el protagonista de su propio aprendizaje. Queremos crear un lugar donde la educación se inspire en las culturas familiares y su diversidad, donde conectemos y respetemos el entorno y el lugar en el que vivimos, fomentando un cambio positivo en nuestra comunidad y en el mundo.<br /><br />
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className='cont-title-line-home flex OoS'><div className='title-line-home'></div></div>


            {/* 3 Secciones Experiencia */}
            <div className='contSeccionesHome flex'>
                <div className='contCardsExperiencia flex'>
                    <div className='cardExperiencia OoS'>
                        <div className='contTituloCards flex'><h2 className='tituloCards'>{(isInglish && "Bilingual and Multicultural Learning") || (!isInglish  && "Enfoque Multicultural Bilingüe")}</h2></div>
                        <div className='contImgExperiencia'>
                            <img className='imgExperiencia' src="/images/bilingual/b1.jpg" alt="" />
                        </div>
                        <div className='contTextoExperiencia flex column'>
                            <p className='textoCards'>
                                {(isInglish  && 
                                <>
                                    We offer a bilingual and multicultural program where children are immersed in the Spanish language throughout the day. We believe that language immersion fosters cultural intelligence and curiosity.
                                </>)
                                || 
                                (!isInglish && 
                                <>
                                    Ofrecemos un programa bilingüe y multicultural en el que los niños están inmersos en el idioma español durante todo el día. Creemos que la inmersión lingüística fomenta la inteligencia cultural y la curiosidad.
                                </>)}
                            </p>
                            <HashLink to="/experiencia/enfoque#top" className="contBotonCard flex"><button className='botonExperiencia'>{(isInglish && "More info") || (!isInglish  && "Seguir leyendo")}</button></HashLink>
                        </div>
                    </div>

                    <div className='cardExperiencia OoS'>
                        <div className='contTituloCards flex'><h2 className='tituloCards'>{(isInglish && "Nature Based Approach") || (!isInglish  && "Aprendizaje en la naturaleza")}</h2></div>
                        <div className='contImgExperiencia'>
                            <img className='imgExperiencia' src="/images/nature/n1.jpg" alt="" />
                        </div>
                        <div className='contTextoExperiencia flex column'>
                            <p className='textoCards'>
                                {(isInglish  && 
                                <>
                                    Our program is nature-based and immersive, with nature at the heart of our curriculum. Located in the heart of a beautiful forest and creeks, we offer quality time for outdoor learning, observation, exploration, and direct contact with nature.
                                </>)
                                || 
                                (!isInglish &&
                                <>
                                    Nuestro programa está basado en la naturaleza e inmersivo, con la naturaleza en el corazón de nuestro currículo. Ubicados en el corazón de un hermoso bosque y arroyos, ofrecemos tiempo de calidad para el aprendizaje al aire libre, la observación, la exploración y el contacto directo con la naturaleza.
                                </>
                                )}
                            </p>
                            <HashLink to="/experiencia/nature#top" className="contBotonCard flex"><button className='botonExperiencia'>{(isInglish && "More info") || (!isInglish  && "Seguir leyendo")}</button></HashLink>
                        </div>
                    </div>

                    <div className='cardExperiencia OoS'>
                        <div className='contTituloCards flex'><h2 className='tituloCards'>{(isInglish && "The Atelier") || (!isInglish  && "Cultura del Atelier")}</h2></div>
                        <div className='contImgExperiencia'>
                            <img className='imgExperiencia program' src="/images/welcome/w1.jpg" alt="" />
                        </div>
                        <div className='contTextoExperiencia flex column'>
                            <p className='textoCards'>
                                {(isInglish && 
                                <>
                                    Our school is seen as a big Atelier where children have spaces where they can imagine, experiment, create and express themselves through different media, materials, and artistic languages. Music, dance, visual arts, and theater experiences are encouraged.
                                </>)
                                || 
                                (!isInglish  && 
                                <>
                                    Nuestra escuela es vista como un Gran Atelier donde los niños tienen espacios para imaginar, experimentar, crear y expresarse a través de diversos medios, materiales y lenguajes artísticos. Se fomentan experiencias en música, danza, artes visuales y teatro.
                                </>
                                )}
                            </p>
                            <HashLink to="/experiencia/atelier#top" className="contBotonCard flex"><button className='botonExperiencia'>{(isInglish && "More info") || (!isInglish  && "Seguir leyendo")}</button></HashLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cont-title-line-home flex OoS'><div className='title-line-home'></div></div>
                      

            {/* Nuestro Equipo */}
            <div className='contSeccionesHome flex'>
                <h2 className='tituloHome OoS'>{(isInglish && "Our team") || (!isInglish  && "Equipo Little Creek")}</h2>
                <h3 className='subTituloHome'>{(isInglish && "Founder/Lead Teacher") || (!isInglish && "Directora")}</h3>
                <div className='contEquipo flex wrap'>
                    <div className='contImgsEquipo flex'>
                        <div className='contPerfil flex column OoS'>
                            <div className='contImgEquipo' id="team">
                                <a href="/images/team_seccion/little_creek_school_team_1.jpg" target='_blank' rel="noreferrer"><img className='imgEquipo' src="/images/team_seccion/little_creek_school_team_1.jpg" alt="team" /></a>
                            </div>
                        </div>
                    </div>
                    <div className='textoTeam flex column OoS'>
                        {isInglish &&
                            <p> 
                                I was born and raised in the beautiful city of Quito, Ecuador, surrounded by mountains and volcanoes. I have had the privilege of teaching and living in countries such as Ecuador, Argentina, Poland, and now the United States. Since moving to Coos Bay, Oregon, in 2021, I have enjoyed life in the forest with my family. I am excited to share the Little Creek experience with the children and families in our community.<br /><br />
                                <HashLink to="/team#top"><button className='botonExperiencia'>More Info</button></HashLink>
                            </p>}
                        {!isInglish &&
                            <p> 
                                Nací y crecí en la hermosa ciudad de Quito, Ecuador, rodeada de montañas y volcanes. He tenido el privilegio de enseñar y vivir en países como Ecuador, Argentina, Polonia y, ahora, en los Estados Unidos. Desde que me mudé a Coos Bay, Oregón, en 2021, he disfrutado de la vida en el bosque con mi familia. Estoy emocionada de compartir la experiencia de Little Creek con los niños y las familias de nuestra comunidad.<br /><br />
                                <HashLink to="/team#top"><button className='botonExperiencia'>Mas Información</button></HashLink>
                            </p>}
                    </div>
                </div>
            </div>
            <div className='cont-title-line-home flex OoS'><div className='title-line-home'></div></div>
         

            {/* Galeria */}
            <div className='contSeccionesHome flex' id="galeria">
                <HashLink to="/home#top"><h2 className='tituloHome OoS' id="galeria">{(isInglish && "Photo gallery") || (!isInglish  && "Galería de Fotos")}</h2></HashLink>
                <Carousel className="OoS"/>
                <HashLink to="/galeria#top" className="contBotonGallery flex"><button className='botonExperiencia'>{(isInglish && "Go to the Gallery") || (!isInglish  && "Ir a la Galería")}</button></HashLink>
            </div>   
            <EndNavBar />
        </div>
    );
}

export default Home;
