import React from 'react';
import { useContext } from 'react';
import { IdiomaContext } from "../../../context/IdiomaContext";
import "./team.css";
import EndNavBar from '../../endNavBar/EndNavBar';

const Team = () => {

    const {isInglish} = useContext(IdiomaContext);

    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Our Team") || (!isInglish && "Nuestro Equipo")}>
                <div className='seccion seccionTeam' id="team"> 
                    {(isInglish && <h2 className="tituloExp tituloTeam">Director and Lead Teacher. <br/> Fernanda Lucas De La Torre</h2>) || (!isInglish  && <h2 className="tituloExp tituloTeam">Fernanda Lucas De La Torre. <br/> Directora / Profesora líder</h2>)}
                    {/* <div className='contPerfil teamContFoto flex column OoS'>
                        <div className='contImgEquipo'>
                            <img className='imgEquipo' src="images/team/ot.jpg" alt="" />
                        </div>
                    </div> */}

                    <div className='contImgYParrafosSecciones'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/team_seccion/little_creek_school_team_1.jpg" alt="team" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                                My journey in education began while studying Dramatic Art in Buenos Aires, Argentina. Through this artistic experience, I discovered the importance of fostering creativity from an early age and its vital role in the holistic development of individuals. This revelation inspired me to pursue a degree in education, and from the first day of my career in this field, I knew I had found my true vocation.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                Mi trayectoria en la educación comenzó mientras estudiaba Arte Dramático en Buenos Aires, Argentina. A través de esta experiencia artística, descubrí la importancia de fomentar la creatividad desde una edad temprana y su papel vital en el desarrollo integral de los individuos. Esta revelación me inspiró a estudiar educación, y desde el primer día de mi carrera en este campo, supe que había encontrado mi verdadera vocación.
                            </p>)}
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                                I hold a degree in Early Childhood Education with a specialization in artistic-expressive languages, as well as a degree in General Education. Over the past 10 years, I have worked in creative and international schools, serving as a lead teacher, academic coordinator, Spanish teacher, and leading theater, artistic installations, and creative writing workshops. I am passionate about continuous growth and regularly seek out new workshops and professional development opportunities to enhance my skills.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p> 
                                Poseo un título en Educación Infantil con especialización en lenguajes artístico-expresivos, así como un título en Educación General. Durante los últimos 10 años, he trabajado en escuelas creativas e internacionales, desempeñándome como maestra principal, coordinadora académica, profesora de español y liderando talleres de teatro, instalaciones artísticas y escritura creativa. Soy apasionada del crecimiento continuo y busco regularmente nuevos talleres y oportunidades de desarrollo profesional para mejorar mis habilidades.
                            </p>)}
                            <img src="/images/hoja.png" className='stickerTeam' alt="leaf" />
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/team_seccion/little_creek_school_team_2.jpg" alt="team" />
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/team_seccion/little_creek_school_team_3.jpg" alt="team" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                                I was born and raised in the beautiful city of Quito, Ecuador, surrounded by mountains and volcanoes. I have had the privilege of teaching and living in countries such as Ecuador, Argentina, Poland, and now the United States. Since moving to Coos Bay, Oregon, in 2021, I have enjoyed life in the forest with my family. I am excited to share the Little Creek experience with the children and families in our community.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p> 
                                Nací y crecí en la hermosa ciudad de Quito, Ecuador, rodeada de montañas y volcanes. He tenido el privilegio de enseñar y vivir en países como Ecuador, Argentina, Polonia y, ahora, en los Estados Unidos. Desde que me mudé a Coos Bay, Oregón, en 2021, he disfrutado de la vida en el bosque con mi familia. Estoy emocionada de compartir la experiencia de Little Creek con los niños y las familias de nuestra comunidad.
                            </p>)}
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                                I am very excited with the possibility of sharing the Little Creek experience with new children and families in our community.<br/>
                            </p>) 
                            || 
                            (!isInglish && 
                            <p> 
                                Estoy muy emocionada de poder compartir la experiencia de Little Creek con nuevos niños y familias de nuestra comunidad.<br/>
                            </p>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/team_seccion/little_creek_school_team_4.jpg" alt="team" />
                        </div>
                    </div>

                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Team;
