import React from 'react';
import { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';
import "./work.css";
import ContactoAdmin from '../contactoAdmin/ContactoAdmin';

const Work = () => {

    const {isInglish} = useContext(IdiomaContext);

    return (
        <>  
            <div className='contSeccion contSeccionAdmin flex column' secciontitle={(isInglish && "Enrollment") || (!isInglish && "Administracion")} subsecciontitle={(isInglish && "Work with us") || (!isInglish && "Trabaja con Nosotros")}>
                <div className='contTextoYContactoAdmin flex'>
                    <div className='textosAdmin flex column'>
                        {(isInglish && <h2 className="titulosExp">Work with us</h2>) || (!isInglish  && <h2 className="titulosExp">Trabaja con Nosotros</h2>)}
                        {(!isInglish && 
                        <>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic necessitatibus, cupiditate rem sint iusto aliquam praesentium consequatur laboriosam mollitia quisquam illum optio repellat? Corporis, cum distinctio ipsa fugiat a eligendi, saepe, cumque natus dolorum praesentium voluptatum ea repellendus perferendis nobis laboriosam aut et libero. Veniam, quisquam mollitia! Corrupti non, at incidunt quisquam quos, quia dolorem voluptatibus ab eum dignissimos corporis? Illum ut praesentium saepe voluptatibus animi? Ipsa eveniet quas placeat, velit repellat non repudiandae eum temporibus similique, in voluptatum labore, fugit magni perspiciatis nihil et magnam quod. Earum vel praesentium, dolore excepturi pariatur quas hic quia aliquid doloribus repellat error?<br/>
                        </>)
                        || 
                        (isInglish  &&             
                        <>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt distinctio voluptate sequi assumenda asperiores aliquam numquam pariatur, error exercitationem ipsum nobis explicabo obcaecati? Et at numquam iure provident voluptas doloribus necessitatibus amet harum, hic perspiciatis, laboriosam consectetur, doloremque inventore obcaecati vel dolorum tempora nostrum quia veniam ullam suscipit quos ab minima? Dolorem ex reiciendis molestias iste ut blanditiis nisi debitis placeat nostrum cupiditate repellendus vero neque, consectetur temporibus facere, saepe cum explicabo labore in ipsam, quas numquam eaque libero excepturi. Sapiente odit ullam dolore eveniet! Veniam eaque non magnam ab adipisci qui nostrum dolores tempora sint, dicta eligendi quibusdam quisquam. <br/>
                        </>)}   
                    </div>
                    <div className='contContactoAdmin flex'>
                        <ContactoAdmin/>     
                    </div>
                </div>
            </div>  
            <EndNavBar />
        </>
    );
}

export default Work;
