import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Bano = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Our program") || (!isInglish && "Nuestro programa")} subsecciontitle={(isInglish && "Potty or toilet learning") || (!isInglish && "Aprender a ir al baño")}>
                <div className='seccion'>
                    
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Potty or toilet learning</>) || (!isInglish && <>Aprender a ir al baño</>)}</h2>
                            {(isInglish && 
                            <p> 
                                We adapt to each child’s developmental stage, whether they are using diapers, training pants, or already using the toilet. We work closely with the children, observing their cues and preferences to provide personalized support, which may include scheduled bathroom visits if necessary. <br />
                                We use natural opportunities, such as diaper changes and mealtimes, to introduce and reinforce toilet training concepts. This integrated approach makes toilet learning a part of their daily routine. We celebrate each child’s progress by focusing on the effort and steps taken, which helps build their confidence and motivation.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                Nos adaptamos a cada niño en su etapa de desarrollo, ya sea que usen pañales, pañales de entrenamiento o ya estén usando el baño. Trabajamos estrechamente con los niños, observando sus señales y preferencias para brindar un apoyo personalizado, que puede incluir visitas programadas al baño si es necesario. <br />
                                Utilizamos oportunidades naturales, como el cambio de pañales y las comidas, para introducir y reforzar los conceptos de uso del baño. Este enfoque integrado hace que el aprendizaje del baño sea parte de su rutina diaria. Celebramos el progreso de cada niño enfocándonos en el esfuerzo y los pasos dados, lo que ayuda a construir su confianza y motivación.
                            </p>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/potty_seccion/little_creek_school_potty.jpg" alt="potty" />
                        </div>
                    </div>
                           
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Bano;
