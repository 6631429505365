import React from 'react';
import { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import "./calendario.css";
import EndNavBar from '../../../endNavBar/EndNavBar';
import ContactoAdmin from '../contactoAdmin/ContactoAdmin';

const Calendario = () => {

    const {isInglish} = useContext(IdiomaContext);

    return (
        <>
            <div className='contSeccion contSeccionAdmin flex column' secciontitle={(isInglish && "Enrollment") || (!isInglish && "Administracion")} subsecciontitle={(isInglish && "Calendar") || (!isInglish && "Calendario")}>
                <div className='contTextoYContactoAdmin flex'>
                    <div className='textosAdmin flex column'>
                        {(isInglish && <h2 className="titulosExp tituloCalendario">School Calendar and Events</h2>) || (!isInglish && <h2 className="titulosExp tituloCalendario">Calendario Escolar y Eventos</h2>)}
                        {(isInglish &&
                        <p>
                            Little Creek School follows the Federal Holidays and the Coos Bay Public School calendar for fall, winter, and spring break. We will be closed all August 2025. 
                        </p>)
                        ||
                        (!isInglish &&
                        <p>
                            Little Creek School sigue los días festivos federales y el calendario de las Escuelas Públicas de Coos Bay para los descansos de otoño, invierno y primavera. Estaremos cerrados durante todo agosto de 2025.
                        </p>)}

                        {(isInglish && <h2 className="titulosExp tituloCalendario">School close days</h2>) || (!isInglish && <h2 className="titulosExp tituloCalendario">Días que nuestra escuela está cerrada:</h2>)}
                        {(isInglish &&
                        <p>
                            Veterans Day: November 11, 2024 <br />
                            Thanksgiving Break: November 25 – 28, 2024 <br />
                            Winter Break: December 23, 2024 – January 2, 2025 <br />
                            Martin Luther King Jr. Day: January 20, 2025 <br />
                            President’s Day: February 17, 2025 <br />
                            Spring Break: March 24 – 27, 2025 <br />
                            Memorial Day: May 26, 2025 <br />
                            Juneteenth: June 19, 2025 <br />
                            Summer Break: August 2025 <br />
                        </p>)
                        ||
                        (!isInglish &&
                        <p>
                            Día de los Veteranos: 11 de noviembre de 2024 <br />
                            Descanso por Acción de Gracias: 25 – 28 de noviembre de 2024 <br />
                            Descanso de Invierno: 23 de diciembre de 2024 – 2 de enero de 2025 <br />
                            Día de Martin Luther King Jr.: 20 de enero de 2025 <br />
                            Día de los Presidentes: 17 de febrero de 2025 <br />
                            Descanso de Primavera: 24 – 27 de marzo de 2025 <br />
                            Día de los Caídos: 26 de mayo de 2025 <br />
                            Juneteenth: 19 de junio de 2025 <br />
                            Descanso de Verano: agosto de 2025 <br />
                           
                        </p>)}
                        {(isInglish && <h2 className="titulosExp tituloCalendario">Little Creek Family Events</h2>) || (!isInglish && <h2 className="titulosExp tituloCalendario">Eventos para la comunidad Little Creek</h2>)}
                        {(isInglish &&
                        <p>
                            Little Creek community - Welcome play and picnic: October 5, 2024 <br />
                            Holidays and traditions - Winter Solstice Celebration: December 2024 (to be confirmed) <br />
                            Welcome Spring – Spring Hike with all Families: April 2025 (to be confirmed) <br />
                            Inti Raymi – Summer Solstice Celebration: June 2025 (to be confirmed) <br />
                        </p>)
                        ||
                        (!isInglish &&
                        <p>
                            Comunidad Little Creek - Bienvenida con juegos y picnic: 5 de octubre de 2024 <br />
                            Fiestas y tradiciones - Celebración del Solsticio de Invierno: diciembre de 2024 (por confirmar) <br />
                            Bienvenida a la Primavera – Caminata de Primavera con todas las familias: abril de 2025 (por confirmar) <br />
                            Inti Raymi – Celebración del Solsticio de Verano: junio de 2025 (por confirmar) <br />
                        </p>)}
                    </div>
                    <div className='contContactoAdmin flex'>
                        <ContactoAdmin/>     
                    </div>
                </div>
            </div>  
            <EndNavBar />
        </>
    );
}

export default Calendario;
