import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Filosofia = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return ( 
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "The Little Creek Experience") || (!isInglish && "La experiencia Little Creek")} subsecciontitle={(isInglish && "Our Philosophy") || (!isInglish && "Nuestra Filosofía")}>
                <div className='seccion'>
                    {(isInglish && <h1 className='tituloExp'>Philosophy</h1>) || (!isInglish && <h1 className='tituloExp tituloNino'>Filosofía Educativa</h1>)}

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opt' src="/images/children_seccion/little_creek_school_children_1.jpg" alt="children" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                                Our educational philosophy is rooted in constructivism and embraces a holistic approach to learning. We focus on nurturing the whole child—mind, body, and spirit—while fostering a love for learning, developing critical thinking and problem-solving skills, and promoting social and emotional growth. <br />
                                <span className='color-experiencia'>We strive to create a learning environment that celebrates diversity, encourages creativity, and values each child's unique strengths and abilities. </span>Our holistic approach incorporates activities that support physical health, emotional well-being, and cognitive development. By offering opportunities for exploration, creation, and collaboration, we prepare children to become active and engaged global citizens.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                Nuestra filosofía educativa está basada en el constructivismo y adopta un enfoque holístico del aprendizaje. Nos enfocamos en apoyar al niño en su totalidad—mente, cuerpo y espíritu—fomentando el amor por el aprendizaje, desarrollando habilidades de pensamiento crítico y resolución de problemas, y promoviendo el crecimiento social y emocional a través de experiencias significativas y contextuales. <br />
                                <span className='color-experiencia'>Nos esforzamos por crear un entorno de aprendizaje que celebre la diversidad, fomente la creatividad y valore las fortalezas y habilidades únicas de cada niño. </span> Nuestro enfoque holístico incluye actividades que apoyan la salud física, el bienestar emocional y el desarrollo cognitivo, facilitando oportunidades para explorar, crear y colaborar. De esta manera, preparamos a los niños para convertirse en ciudadanos activos y comprometidos del mundo.
                            </p>)}
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Children as protagonists in their learning</>) || (!isInglish && <>El niño como protagonista de su aprendizaje</>)}</h2>
                            {(isInglish && 
                            <p>
                                Children are active, capable, and valuable members of our community, citizens of the world with rights and duties. They are authors and builders of their learning, with extraordinary potentialities that are manifested in an unceasing exchange with the cultural and social context. They have their own ideas, express opinions, make independent choices and can play and work well with others.<br />
                                <span className='color-experiencia'>At Little Creek we invite children to explore, question and interpret the world, and create a solid foundation of experiences to guide them in reaching their full potential.</span> <br /> 
                                We encourage children to learn from one another. We provide spaces where they work and play together in small groups from a very early age. We promote exchanges to foster listening skills for other points of view and to respect the opinions and feelings of others.<br /> 
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                Los niños son miembros activos, capaces y valiosos de nuestra comunidad, ciudadanos del mundo con derechos y deberes. Son autores y constructores de sus aprendizajes, con extraordinarias potencialidades que se manifiestan en un incesante intercambio con el contexto cultural y social. Tienen sus propias ideas, expresan opiniones, toman decisiones independientes y pueden jugar y trabajar bien con otros.<br />
                                <span className='color-experiencia'>En Little Creek invitamos a los niños a explorar, cuestionar e interpretar el mundo y crear una base sólida de experiencias para guiarlos a alcanzar su máximo potencial.</span> <br />
                                Animamos a los niños a aprender unos de otros. Brindamos espacios donde trabajan y juegan juntos en pequeños grupos desde una edad muy temprana. Promovemos los intercambios para fomentar la capacidad de escuchar otros puntos de vista y respetar las opiniones y sentimientos de los demás.<br />
                            </p>)}
                        </div>
                        <div className='contImgSecciones flex'>
                            <img className='imgSecciones' src="/images/children_seccion/little_creek_school_children_2.jpg" alt="children" />
                        </div>
                    </div>     

                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Filosofia;
