import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Guia = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Our program") || (!isInglish && "Nuestro programa")} subsecciontitle={(isInglish && "Positive guidance") || (!isInglish && "Guía positiva en resolución de conflictos")}>
                <div className='seccion'>
                 
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Positive guidance</>) || (!isInglish && <>Guía positiva en resolución de conflictos</>)}</h2>
                            {(isInglish && 
                            <p> 
                                <span className='color-programa'>At Little Creek, our goal is to help children develop strong social and emotional skills, fostering their sense of belonging and self-worth. </span>
                                We believe that children with these skills tend to be happier, more motivated to learn, and form positive relationships with both peers and adults. Using the principles of Positive Discipline, we guide children to participate eagerly in daily activities, approach new experiences with confidence, and practice problem-solving in constructive ways. <br />
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                <span className='color-programa'>En Little Creek, nuestro objetivo es ayudar a los niños a desarrollar habilidades sociales y emocionales sólidas, fomentando su sentido de pertenencia y autoestima. </span>
                                Creemos que los niños con estas habilidades tienden a ser más felices, están más motivados para aprender y forman relaciones positivas con sus compañeros y adultos. Utilizando los principios de la Disciplina Positiva, guiamos a los niños para que participen con entusiasmo en las actividades diarias, enfrenten nuevas experiencias con confianza y practiquen la resolución de problemas de manera constructiva. <br />
                            </p>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/guidance_seccion/little_creek_school_guidance_ 1.jpg" alt="guidance" />
                        </div>
                    </div> 

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/guidance_seccion/little_creek_school_guidance_ 2.jpg" alt="guidance" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                               <span className='color-programa'>We emphasize compassion and mutual respect in conflict resolution, creating a supportive environment where children feel secure, valued, and heard. </span>
                               When children feel safe, they are more willing to explore solutions to challenges, building resilience and self-regulation. Through reflection, open dialogue, and nurturing a sense of community, we foster trust, cooperation, and empathy, helping children learn responsibility and care for others. <br />
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                <span className='color-programa'>Enfatizamos la compasión y el respeto mutuo en la resolución de conflictos, creando un entorno de apoyo donde los niños se sientan seguros, valorados y escuchados. </span>
                                Cuando los niños se sienten seguros, están más dispuestos a explorar soluciones a los desafíos, desarrollando resiliencia y autorregulación. A través de la reflexión, el diálogo abierto y el fomento de un sentido de comunidad, promovemos la confianza, la cooperación y la empatía, ayudando a los niños a aprender responsabilidad y cuidado por los demás. <br />
                            </p>)}
                        </div>
                    </div> 
                                 
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Guia;
