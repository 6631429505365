import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';
import "./nature.css";

const Nature = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return ( 
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "The Little Creek Experience") || (!isInglish && "La experiencia Little Creek")} subsecciontitle={(isInglish && "Nature Based Approach") || (!isInglish && "Aprendizaje en la naturaleza")}>
                <div className='seccion'>
                                        
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opr ofcon' src="/images/nature_seccion/little_creek_school_Nature_Based Approach_2.jpg" alt="bilingual" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Nature-Based and Immersive Learning</>) || (!isInglish && <>Aprendizaje Basado en la Naturaleza e Inmersivo</>)}</h2>
                            {(isInglish && 
                            <p> 
                                <span className='color-experiencia'>Our school program is nature-based and immersive, with nature at the heart of our curriculum. </span>
                                Nestled in a beautiful forest with creeks, we provide children with abundant opportunities for outdoor learning, exploration, and direct contact with the natural world. They get their hands in the soil, explore plants, and observe living organisms, deepening their understanding of the natural cycles and rhythms that define life. Observing changes in their environment throughout the year fosters a sense of belonging and responsibility for the well-being of our planet.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                <span className='color-experiencia'>Nuestro programa escolar está basado en la naturaleza e inmersivo, con la naturaleza en el corazón de nuestro currículo. </span>
                                Ubicados en un hermoso bosque con arroyos, ofrecemos a los niños abundantes oportunidades para el aprendizaje al aire libre, la exploración y el contacto directo con el mundo natural. Los niños tienen las manos en la tierra, exploran plantas y observan organismos vivos, profundizando su comprensión de los ciclos y ritmos naturales que definen la vida. Observar los cambios en su entorno a lo largo del año fomenta un sentido de pertenencia y responsabilidad por el bienestar de nuestro planeta.
                            </p>)}
                        </div>
                    </div>
                    
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                                <span className='color-experiencia'>They are creating a sense of belonging with their surroundings and developing a sense of care for the natural parts of our planet.</span><br />
                                Kids interact in nature through play, a nature play that is fun, open-ended, self-directed, and freely chosen.<br />
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                <span className='color-experiencia'>Están creando un sentido de lugar con su entorno y desarrollando un sentido de cuidado por las partes naturales de nuestro planeta.</span><br />
                                Los niños interactúan en la naturaleza a través del juego, un juego natural que es divertido, abierto, autodirigido y elegido libremente.<br />
                            </p>)}
                            <img src="/images/rana.png" className='stickerNature' alt="frog" />
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/nature_seccion/little_creek_school_Nature_Based Approach_5.jpg" alt="bilingual" />
                        </div>
                    </div>
                    
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/nature_seccion/little_creek_school_Nature_Based Approach_6.jpg" alt="bilingual" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="subTitulosExp">{(isInglish && <>Learning from Indigenous Knowledges and Lifeways</>) || (!isInglish && <>Aprendizaje de los Conocimientos y Formas de Vida Indígenas</>)}</h2>
                            {(isInglish && 
                            <p> 
                                We incorporate the vision and knowledge of indigenous and local peoples, reflecting respect and admiration for the relationships between plants, animals, natural phenomena, landscapes, and timing of events. Storytelling serves as a key tool for transmitting ethics, values, and traditions, bridging connections with the past. Through storytelling, we share teachings on traditional customs, spiritual beliefs, and ecological wisdom, honoring its role across indigenous cultures.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                Incorporamos la visión y el conocimiento de los pueblos indígenas y locales, reflejando respeto y admiración por las relaciones entre plantas, animales, fenómenos naturales, paisajes y el momento de los eventos. La narración de historias sirve como una herramienta clave para transmitir ética, valores y tradiciones, reconectando con el pasado. A través de la narración de historias, compartimos enseñanzas sobre costumbres tradicionales, creencias espirituales y sabiduría ecológica, honrando su papel en las culturas indígenas.
                            </p>)}
                        </div>
                    </div>   
                    
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="subTitulosExp">{(isInglish && <>Planet Guardians</>) || (!isInglish && <>Guardianes del planeta</>)}</h2>
                            {(isInglish && 
                            <p> 
                                Caring for and protecting nature and the environment are central goals of our program. 
                                <span className='color-experiencia'>We promote developmentally appropriate practices to support holistic child development and environmental education. </span>
                                By learning in, about, and for nature, children develop environmental literacy and a sense of care for the natural world. <br />
                                To support sustainable living, we encourage practices such as recycling, reducing single-use plastics, conserving water, composting, using biodegradable cleaning products, maximizing natural light to reduce energy consumption, and incorporating organic products and seasonal gardening. <br />
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                Cuidar y proteger la naturaleza y el medio ambiente son objetivos centrales de nuestro programa. 
                                <span className='color-experiencia'> Promovemos prácticas adecuadas al desarrollo para apoyar el desarrollo infantil integral y la educación ambiental. </span>
                                Al aprender sobre y para la naturaleza, los niños desarrollan alfabetización ambiental y un sentido de cuidado por el mundo natural. <br />
                                Para apoyar la vida sostenible, fomentamos prácticas como el reciclaje, la reducción de plásticos de un solo uso, la conservación del agua, el compostaje, el uso de productos de limpieza biodegradables, la maximización de la luz natural para reducir el consumo de energía, y la incorporación de productos orgánicos y jardinería estacional. <br />
                            </p>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/nature_seccion/little_creek_school_Nature_Based Approach_3.jpg" alt="bilingual" />
                        </div>
                    </div>               
                                                
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Nature;
