import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Habitos = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Our Program") || (!isInglish && "Nuestro programa")} subsecciontitle={(isInglish && "Healthy Food Habits") || (!isInglish && "Hábitos de alimentación saludable")}>
                <div className='seccion'>
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/food_seccion/little_creek_school_food_1.jpg" alt="food" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Healthy Food Habits</>) || (!isInglish && <>Hábitos de alimentación saludable</>)}</h2>
                            {(isInglish && 
                            <p> 
                                <span className='color-programa'>We believe that children need food not only for nourishment but also as a means to connect, socialize, feel joy, and be inspired. </span> <br />
                                By involving them in the preparation of their breakfast and some snacks, we teach children where food comes from, its smells, colors, shapes, and textures. Through this hands-on approach, they also develop math and science skills, fine motor coordination, and an understanding of healthy habits. <br />
                                Our commitment to health extends to the food we serve, which is prepared and offered on-site using minimally processed and packaged items. We prioritize locally sourced, organic foods whenever possible, with daily offerings of fresh fruits and vegetables. 
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                <span className='color-programa'>Creemos que los niños necesitan la comida no solo para nutrirse, sino también como un medio para conectarse, socializar, sentir alegría e inspirarse. </span> <br />
                                Al involucrarlos en la preparación de su desayuno y algunos refrigerios, les enseñamos de dónde proviene la comida, sus olores, colores, formas y texturas. A través de este enfoque práctico, también desarrollan habilidades matemáticas y científicas, coordinación motora fina y una comprensión de los hábitos saludables. <br />
                                Nuestro compromiso con la salud se extiende a la comida que servimos, que se prepara y ofrece en el lugar utilizando productos mínimamente procesados y envasados. Priorizamos alimentos orgánicos y de origen local siempre que es posible, con ofertas diarias de frutas y verduras frescas.
                            </p>)}
                        </div>
                    </div>
                    
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                                <span className='color-programa'>All meals are eaten family-style, fostering a sense of community as children share stories and special moments. </span><br />
                                We encourage independence and participation during mealtimes, motivating children to set the table, serve themselves, and help with clean-up tasks. Using child-sized glass and porcelain dishes, metal utensils, and fabric napkins enhances their understanding of materials and builds responsibility. This approach lays a foundation for lifelong healthy food habits.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                <span className='color-programa'>Todas las comidas se realizan en un formato familiar, fomentando un sentido de comunidad mientras los niños comparten historias y momentos especiales. </span><br />
                                Fomentamos la independencia y la participación durante las comidas, motivando a los niños a poner la mesa, servirse ellos mismos y ayudar en las tareas de limpieza. El uso de platos de vidrio y porcelana del tamaño de los niños, utensilios de metal y servilletas de tela mejora su comprensión de los materiales y fomenta la responsabilidad. Este enfoque sienta las bases para hábitos alimenticios saludables a lo largo de la vida.
                            </p>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/food_seccion/little_creek_school_food_2.jpg" alt="food" />
                        </div>
                    </div>
                                       
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Habitos;
