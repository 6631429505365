import React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { IdiomaContext } from "../../../context/IdiomaContext";
import EndNavBar from '../../endNavBar/EndNavBar';
import "./galeria.css";
import close from "./close.png";

const Galeria = () => {

    const {isInglish} = useContext(IdiomaContext);
    const contImgDesplegada = useRef();
    const [imgCarousel, setImgCarousel] = useState();
    
    useEffect(() => {
        const imageContainers = document.querySelector(".contImagenesGaleria").childNodes;
        imageContainers.forEach((container, index) => {
            container.firstChild.setAttribute("position", index);
            container.addEventListener("click", () => {
                const imageSrcMin = container.firstChild.src;
                setImgCarousel(<img className='imagenDesplegada' src={imageSrcMin.slice(0, -7) + ".jpg"} alt="Img Gallery" position={index}/>)
            })
        })
    }, []);
           
    useEffect(() => {
        if (imgCarousel) {
            contImgDesplegada.current.firstChild.classList.remove("imagenOpacityOn"); 
            document.querySelector(".imagenDesplegada").onload = () => {                        //Aunque ya cambió "imgCarousel" esperamos que la imagen termine de cargar
                contImgDesplegada.current.firstChild.classList.add("imagenOpacityOn");      
                document.body.style.overflow = "hidden";         //Anulamos scroll
                contImgDesplegada.current.style.display = "flex";
            }    
        }
    }, [imgCarousel]);

    const exit = () => {
        contImgDesplegada.current.style.display = "none";
        document.body.style.overflow = "auto";                  //Habilitamos scroll
    }

    const changeImage = (next) => {
        const imageContainers = document.querySelector(".contImagenesGaleria").childNodes;
        const imagePosition = parseInt(contImgDesplegada.current.firstChild.getAttribute("position"));
        let newImagePosition;

        if (next) {
            newImagePosition = imagePosition + 1;
        } else {
            newImagePosition = imagePosition - 1;
        }

        if (newImagePosition === imageContainers.length) newImagePosition = 0;
        if (newImagePosition === -1) newImagePosition = imageContainers.length - 1;
        const imageSrcMin = imageContainers[newImagePosition].firstChild.src;
        setImgCarousel(<img className='imagenDesplegada' src={imageSrcMin.slice(0, -7) + ".jpg"} alt="Img Gallery" position={newImagePosition}/>);
    }

     
    return (
        <>
            <div className='contImagenDesplegada flex' ref={contImgDesplegada}>  
                {imgCarousel}
                <span className="iconNext flex" onClick={() => changeImage(true)}>&lt;</span>
                <span className="iconPrev flex" onClick={() => changeImage(false)}>&lt;</span>
                <img className='iconClose' onClick={exit} src={close} alt="Close Icon" />
            </div>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Photo gallery") || (!isInglish && "Galería de Fotos")} subsecciontitle={(isInglish && "Photo gallery") || (!isInglish && "Galería de Fotos")}>
                <div className='seccion seccion75 flex column'> 
                    {(isInglish && <h1 className='tituloExp'>Photo gallery</h1>) || (!isInglish  && <h1 className='tituloExp'>Galería de Fotos</h1>)}

                    <div className='contImagenesGaleria flex wrap'>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/1min.jpg" alt="Little Creek School Img" className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/2min.jpg" alt="Little Creek School Img"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/3min.jpg" alt="Little Creek School Img"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/4min.jpg" alt="Little Creek School Img"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/5min.jpg" alt="Little Creek School Img"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/6min.jpg" alt="Little Creek School Img"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/7min.jpg" alt="Little Creek School Img" className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/8min.jpg" alt="Little Creek School Img"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/9min.jpg" alt="Little Creek School Img"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/10min.jpg" alt="Little Creek School Img"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/11min.jpg" alt="Little Creek School Img" className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_1min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div> 
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_2min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_3min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_4min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_5min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_6min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_7min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_8min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_9min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_10min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_11min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_12min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_13min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_14min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_15min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_16min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_17min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_18min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_19min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_20min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_21min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_22min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_23min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_24min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_25min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_26min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/mayo_2023/Little_Creek_May_2023_27min.jpg" alt="Little Creek May 2023"  className='imgGaleriaSeccion'/></div>

                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_1min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_2min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_3min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_4min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_5min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_6min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_7min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_8min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_9min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_10min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_11min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_12min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_13min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_14min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_15min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_16min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_17min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_18min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_19min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_20min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_21min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_22min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_23min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_24min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_25min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_26min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_27min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_28min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_29min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_30min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_31min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_32min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_33min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_34min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_35min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_36min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_37min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_38min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_39min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_40min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                        <div className='contImgGaleriaSeccion'><img src="images/galeria/sep_2023/little_creek_school_sep_2023_41min.jpg" alt="Little Creek Sep 2023"  className='imgGaleriaSeccion'/></div>
                    </div>
                </div>
            </div>  

            <EndNavBar />
        </>
    );
}

export default Galeria;
