import React from 'react';
import "./autoCarousel.css";
import { useEffect, useRef } from 'react';

const AutoCarousel = () => {
     
    const contImgHome = useRef();                       /*Efecto zoom de imagenes en portada de Home. Trabaja junto con CSS: .contImgHome .imgHome @keyframes zoomHome */
    useEffect(() => {
        const imgs = contImgHome.current.childNodes;
        imgs[0].style.display = "block";

        let i = 1;
        setInterval(() => {
            for (const img of imgs) {
                img.style.display = "none";
            }
            imgs[i].style.display = "block";
            i++;
            if(i === imgs.length) i = 0;
        }, 6000);
    
    }, []);

    return (
        <div className='contImgHome' ref={contImgHome}>
            <img src="/images/welcome/w2.jpg" alt="welcome" className='imgHome'/> 
            <img src="/images/welcome/w3.jpg" alt="welcome" className='imgHome'/> 
            <img src="/images/welcome/w4.jpg" alt="welcome" className='imgHome'/> 
            <img src="/images/welcome/w5.jpg" alt="welcome" className='imgHome'/>     
        </div>
    );
}

export default AutoCarousel;
