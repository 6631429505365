import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Enfoque = () => {
    const {isInglish} = useContext(IdiomaContext);
    
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "The Little Creek Experience") || (!isInglish && "La experiencia Little Creek")} subsecciontitle={(isInglish && "Bilingual and Multicultural Learning") || (!isInglish && "Enfoque Multicultural Bilingüe")}>
                <div className='seccion'>
                    <h1 className='tituloExp'>{(isInglish && <>Bilingual and Multicultural Learning</>) || (!isInglish && <>Aprendizaje Bilingüe y Multicultural</>)}</h1>
                                      
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/bilingual_seccion/1_bilingual.jpg" alt="bilingual" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                                Little Creek offers a bilingual and multicultural program where children are immersed in the Spanish language throughout the day.
                                <span className='color-experiencia'>We believe that language immersion fosters cultural intelligence and curiosity, enriching children's understanding of the world.</span><br />
                                Our bilingual and multicultural education promotes fundamental values of tolerance, respect, and empathy. By learning about diverse Hispanic and Latin American cultures, children gain different perspectives that complement and contrast with their own family cultures, broadening their worldview.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                Little Creek ofrece un programa bilingüe y multicultural en el que los niños están inmersos en el idioma español durante todo el día. 
                                <span className='color-experiencia'>Creemos que la inmersión lingüística fomenta la inteligencia cultural y la curiosidad, enriqueciendo la comprensión de los niños sobre el mundo.</span> <br />
                                Nuestra educación bilingüe y multicultural promueve valores fundamentales de tolerancia, respeto y empatía. Al aprender sobre diversas culturas hispánicas y latinoamericanas, los niños adquieren perspectivas diferentes que complementan y contrastan con sus propias culturas familiares, ampliando su visión del mundo.
                            </p>)}
                        </div>
                    </div>
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Bilingual and Multicultural Learning</>) || (!isInglish && <>Enfoque Multicultural Bilingüe</>)}</h2>
                            {(isInglish && 
                            <p>
                                The Spanish language is learned through engaging experiences and projects that integrate its culture, including music, literature, food, stories, traditions, festivities, customs, and dances. We emphasize that learning another language goes beyond grammar, allowing children to connect with it in a more meaningful and profound way.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                El idioma español se aprende a través de experiencias y proyectos envolventes que integran su cultura, incluyendo música, literatura, comida, historias, tradiciones, festividades, costumbres y danzas. Enfatizamos que aprender otro idioma va más allá de la gramática, permitiendo a los niños conectarse con él de una manera más significativa y profunda.
                            </p>)}
                        </div>
                        <div className='contImgSecciones flex'>
                            <img className='imgSecciones' src="/images/bilingual_seccion/2_bilingual.jpg" alt="bilingual" />
                        </div>
                    </div>                    
                                                           
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/bilingual_seccion/little_creek_school_spanish_book_library.jpg" alt="bilingual" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h3 className='subTitulosExp'>{(isInglish && <>Spanish books library</>) || (!isInglish && <>Biblioteca de libros en español</>)}</h3>
                            {(isInglish && 
                            <p>
                                At Little Creek, we have our own Spanish library featuring books from various Hispanic and Latin American authors that highlight the culture and characteristics of the Spanish language.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                En Little Creek, contamos con nuestra propia biblioteca en español que presenta libros de diversos autores hispánicos y latinoamericanos que destacan la cultura y las características del idioma español.
                            </p>)}
                        </div>
                    </div>   
                                  
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                        <h3 className='subTitulosExp'>{(isInglish && <>Cultural materials</>) || (!isInglish && <>Materiales culturales</>)}</h3>
                        {(isInglish && 
                        <p>
                            We also have a special collection of multicultural items from different countries that showcase the beautiful cultural diversity in our world. We support local artists and artisans from our community and Oregon, as well as Latin American artists and artisans.<br />
                        </p>)
                        || 
                        (!isInglish && 
                        <p>
                            También disponemos de una colección especial de artículos multiculturales de diferentes países que muestran la hermosa diversidad cultural en nuestro mundo. Apoyamos a artistas y artesanos locales de nuestra comunidad y Oregón, así como a artistas y artesanos latinoamericanos.<br />
                        </p>)}    
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/bilingual_seccion/5_otavalo.jpg" alt="bilingual" />
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/bilingual_seccion/little_creek_school_La_Guaytamba.jpg" alt="La Guaytamba" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                        {(isInglish && 
                            <p>
                                <span> Our logo and illustrations of the Little Creek brand were designed by the artist <a href="https://www.instagram.com/la.guaytamba/?igshid=YmMyMTA2M2Y%3D" target="_blank" className='linkAdmis' rel="noreferrer">La Guaytamba</a> (Quito-Ecuador).</span> <br />
                                <span> Our first toys and crafts that are already in our school come from the indigenous market of <a href="https://www.bbc.com/travel/article/20131230-otavalo-the-land-of-andean-artistry" target="_blank" className='linkAdmis' rel="noreferrer">Otavalo</a> in Ecuador. </span>
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                <span> Nuestro logo e ilustraciones de la marca Little Creek fueron diseñados por la artista <a href="https://www.instagram.com/la.guaytamba/?igshid=YmMyMTA2M2Y%3D" target="_blank" className='linkAdmis' rel="noreferrer">La Guaytamba</a> (Quito-Ecuador).</span> <br />
                                <span> Nuestros primeros juguetes y artesanías que ya están en nuestra escuela provienen del mercado indígena de <a href="https://www.bbc.com/travel/article/20131230-otavalo-the-land-of-andean-artistry" target="_blank" className='linkAdmis' rel="noreferrer">Otavalo</a> en Ecuador. </span>
                            </p>)}    
                        </div>
                    </div>

                    <div className='contImgSolas flex wrap'>
                        <div className='contImgSola'>
                            <img className='imgSolas' src="/images/bilingual_seccion/3_otavalo.jpg" alt="bilingual" />
                        </div>
                        <div className='contImgSola'>
                            <img className='imgSolas' src="/images/bilingual_seccion/4_otavalo.jpg" alt="bilingual" />
                        </div>    
                    </div>    
                                                                                       
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Enfoque;
