import React from 'react';
import "./carousel.css";
import { useEffect } from 'react';
import { useRef } from 'react';
import collapseIcon from "./assets/collapse.png";
import expandIcon from "./assets/expand.png";

const Carousel = () => {
    const contImagenes = useRef();
    const contListaDeImagenes = useRef();
    const carousel = useRef();
    const iconExpand = useRef();
    const iconCollapse = useRef();
    let puntero = 0;
       
    const toggleCarousel = () => {                                      //Maximizamos Carouel
        if (carousel.current.style.position !== "fixed") {
            carousel.current.style.position = "fixed";
            carousel.current.style.top = "0";
            carousel.current.style.left = "0";
            carousel.current.style.zIndex = "9100";
            carousel.current.style.height = "100vh";
            carousel.current.style.width = "100vw";
            carousel.current.style.border = "none";
            document.body.style.overflow = "hidden";
            iconExpand.current.style.display = "none";
            iconCollapse.current.style.display = "flex"; 
            const images = document.getElementsByClassName("imgCarousel");
            for (const image of images) {
                image.style.objectFit = "contain";
            }       
        } else {                                                        //Minimizamos Carousel
            carousel.current.style = "";
            document.body.style.overflow = "auto";
            iconExpand.current.style.display = "flex";
            iconCollapse.current.style.display = "none";
            const images = document.getElementsByClassName("imgCarousel");
            for (const image of images) {
                image.style.objectFit = "cover";
            }  
        }      
    }

    const clearAnimations = () => {                                     //Borramos todas las animaciones para que las imagenes no queden desplazadas
        const animations = document.getAnimations();
        for (const animation of animations) {
            animation.cancel();
        }
    }
    
    const setearImagenCarrousel = (pos) => {
        clearAnimations();
        puntero = pos;
        const arrayImagenes = contImagenes.current.childNodes;          //Ponemos todas la imagenes del carousel en display "none"
        for (const imagen of arrayImagenes) {
            imagen.style.display = "none";
        }
        if (contImagenes.current.childNodes[pos].firstChild.src.includes("min.jpg")) contImagenes.current.childNodes[pos].firstChild.src = contImagenes.current.childNodes[pos].firstChild.src.slice(0, -7) + ".jpg"; //Si la imagen seleccionada esta en tamaño chico la seteamos en grande para que se vea bien
        contImagenes.current.childNodes[pos].style.display = "flex";    //Ponemos la elegida en display "flex"
        marcarImagenLista(pos);
    }

    useEffect(() => {          
        contImagenes.current.childNodes[0].firstChild.src = contImagenes.current.childNodes[0].firstChild.src.slice(0, -7) + ".jpg";  //Cambiamos el link de la primera imagen que aparece en el carousel a tamaño grande                              
        contImagenes.current.childNodes[0].style.display = "flex";      //Primera imagen de carousel en display "flex"

        const listaImagenes = contImagenes.current.childNodes           //Generamos lista inferior de imagenes a partir del carousel
        contListaDeImagenes.current.innerHTML = "";
        listaImagenes.forEach((div, index) => {
            const imagen = div.firstChild;
            contListaDeImagenes.current.innerHTML += `<div class='contImgListaCarousel' pos=${index}><img src=${imagen.src} alt="Lista Carousel" class="imgCarousel" pos=${index} /></div>`;
        })
        contListaDeImagenes.current.childNodes[0].style.outline = "4px solid orange";
        contListaDeImagenes.current.childNodes[0].style.filter = "grayscale(0%)";
        
        const imagenes = contListaDeImagenes.current.childNodes;        //ponemos escuchadores en todas las imagenes de la lista inferior
        for (const imagen of imagenes) {
            imagen.addEventListener("click", (e) => {
                setearImagenCarrousel(e.target.getAttribute("pos"));
            })
        }   // eslint-disable-next-line
    }, []);

    const marcarImagenLista = (pos) => {                            //Marcamos la imagen que corresponda de la lista segun la que este seleccionada en el carousel
        const imagenes = contListaDeImagenes.current.childNodes;
        for (const imagen of imagenes) { 
            imagen.style.outline = "none";
            imagen.style.filter = "grayscale(0%)"       //Acá podemos poner grayscale 50% si queremos que las fotos no seleccionadas esten con menos color
        }
        contListaDeImagenes.current.childNodes[pos].style.outline = "4px solid orange"
        contListaDeImagenes.current.childNodes[pos].style.filter = "grayscale(0%)"
    }
     
    const changeImage = (e) => {
        const arrayImagenes = contImagenes.current.childNodes;          //Ponemos todas la imagenes en display "none"
        for (const imagen of arrayImagenes) {
            imagen.style.display = "none";
        }
         
        e.target.getAttribute("opc") === "next" ? puntero ++ : puntero --;
        if (puntero === -1) puntero = arrayImagenes.length - 1;
        if (puntero === arrayImagenes.length) puntero = 0;
       
        marcarImagenLista(puntero);

        const animate = (initx, endtx, inio, endo, punteroAnterior) => {
            if (arrayImagenes[puntero].firstChild.src.includes("min.jpg")) arrayImagenes[puntero].firstChild.src = arrayImagenes[puntero].firstChild.src.slice(0, -7) + ".jpg";  //Vamos pasando los links de las imagenes que se van pasando con la flecha a tamaño grande a medida que se muestran
            arrayImagenes[puntero].animate([                                //Animacion de imagen entrante
            // keyframes
            { transform: `translateX(${initx}%)`, opacity: `${inio}%`}, 
            { transform: `translateX(${endtx}%)`, opacity: `${endo}%`}
            ], {
                // timing options
                duration: 500,
                fill: "forwards",
                easing: "ease-out"
            })

            if (arrayImagenes[punteroAnterior].firstChild.src.includes("min.jpg")) arrayImagenes[punteroAnterior].firstChild.src = arrayImagenes[punteroAnterior].firstChild.src.slice(0, -7) + ".jpg";  //Vamos pasando los links de las imagenes que se van pasando con la flecha a tamaño grande a medida que se muestran
            arrayImagenes[punteroAnterior].animate([               //Animación de imagen saliente
            // keyframes
            { transform: `translateX(${endtx}%)`}, 
            { transform: `translateX(${-initx}%)`}
            ], {
                // timing options
                duration: 500,
                fill: "forwards",
                easing: "ease-out"
            })
        }

        const calcPunteroAnterior = (punt) => {                     //Mantenamos el puntero dentro del array de imagenes
            if (punt === -1) return (arrayImagenes.length - 1);
            if (punt === arrayImagenes.length) return 0;
            return punt;
        }

        if (e.target.getAttribute("opc") === "next") {
            const punteroAnterior = calcPunteroAnterior(puntero - 1);
            arrayImagenes[punteroAnterior].style.display = "flex";
            animate (100, 0, 0, 100, punteroAnterior);
        }    
        if (e.target.getAttribute("opc") === "prev") {
            const punteroAnterior = calcPunteroAnterior(puntero + 1);
            arrayImagenes[punteroAnterior].style.display = "flex";
            animate (-100, 0, 0, 100, punteroAnterior);
        }   
                
        arrayImagenes[puntero].style.display = "flex";
    }
    
    return (
        <div className='contCarousel flex column OoS'>
            <div className='carousel' ref={carousel}>
                <div className='carouselImagenes' ref={contImagenes}>
                    <div className="contImgCarousel">
                        <img src="/images/galeria/1min.jpg" className="imgCarousel" alt="..." />
                    </div>
                    <div className="contImgCarousel">
                        <img src="/images/galeria/2min.jpg" className="imgCarousel" alt="..." />
                    </div>
                    <div className="contImgCarousel">
                        <img src="/images/galeria/3min.jpg" className="imgCarousel" alt="..." />
                    </div>
                    <div className="contImgCarousel">
                        <img src="/images/galeria/4min.jpg" className="imgCarousel" alt="..." />
                    </div>
                    <div className="contImgCarousel">
                        <img src="/images/galeria/5min.jpg" className="imgCarousel" alt="..." />
                    </div>
                    <div className="contImgCarousel">
                        <img src="/images/galeria/6min.jpg" className="imgCarousel" alt="..." />
                    </div>
                    <div className="contImgCarousel">
                        <img src="/images/galeria/7min.jpg" className="imgCarousel" alt="..." />
                    </div>
                    <div className="contImgCarousel">
                        <img src="/images/galeria/8min.jpg" className="imgCarousel" alt="..." />
                    </div>
                    <div className="contImgCarousel">
                        <img src="/images/galeria/9min.jpg" className="imgCarousel" alt="..." />
                    </div>
                    <div className="contImgCarousel">
                        <img src="/images/galeria/10min.jpg" className="imgCarousel" alt="..." />
                    </div>
                    <div className="contImgCarousel">
                        <img src="/images/galeria/11min.jpg" className="imgCarousel" alt="..." />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_1min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_2min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_3min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_4min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_5min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_6min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_7min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_8min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_9min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_10min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_11min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_12min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_13min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_14min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_15min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_16min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_17min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_18min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_19min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_20min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_21min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_22min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_23min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_24min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_25min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_26min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                    <div className="contImgCarousel">
                        <img src="images/galeria/mayo_2023/Little_Creek_May_2023_27min.jpg" alt="Little Creek May 2023" className="imgCarousel" />
                    </div>
                </div>
                <div className='carouselBotones'>
                    <span className='previous flex' onClick={(e) => changeImage(e)} opc="prev">&lt;</span>
                    <span className='next flex' onClick={(e) => changeImage(e)} opc="next">&lt;</span>
                    <img src={expandIcon} alt="expand" className='iconE' ref={iconExpand} onClick={toggleCarousel}/>
                    <img src={collapseIcon} alt="expand" className='iconC' ref={iconCollapse} onClick={toggleCarousel}/>
                </div>
            </div>
            <div className='contContImgs'><div className='contListaDeImagenes' ref={contListaDeImagenes}></div></div>
        </div>
    );
}

export default Carousel;


