import React from 'react';
import "./contactoAdmin.css";
import { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";

const ContactoAdmin = () => {

    const {isInglish} = useContext(IdiomaContext);

    return (
        <div className='contactoAdmin flex column'>
            <img src="/images/flores.png" className="stickerAdmin" alt="Himalayan BlackBerry"/>
            <div className='contTextoContactoAdmin flex column'>
                <p className='tituloContactoAdmin'>{(isInglish && "Contact") || (!isInglish && "Contacto")}:</p>
                <p>93507 Easy Creek Lane </p>
                <p>Coos Bay, OR 97420 </p>
                <p>info@littlecreekschool.com </p>
                <p>(541) 269 - 1230 </p>
            </div>
        </div>
    );
}

export default ContactoAdmin;
