import "./form.css";
import React, { useContext } from 'react';
import { IdiomaContext } from "../../context/IdiomaContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";


const Form = () => {

    const {isInglish} = useContext(IdiomaContext);
    const navigate = useNavigate();

    const sendForm = async () => {
        const form = document.querySelector(".formEnrollment");
                       
        const formData = new FormData(form);
        const data = Object.fromEntries(formData);

        if (
            data.firstname.trim() !== "" &&
            data.lastname.trim() !== "" &&
            data.date1.trim() !== "" &&
            data.date2.trim() !== "" &&
            data.year.trim() !== "" &&
            data.name_lastname1.trim() !== "" &&
            data.phone1.trim() !== "" &&
            data.email1.trim() !== "" &&
            data.message.trim() !== ""
        ) {
            if (isInglish) [data.date1, data.date2] = [data.date2, data.date1];

            try {
                const respJSON = await fetch ("/sendForm.php", {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                const respOBJ = await respJSON.json();
                console.log(respOBJ);
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: ((isInglish && 'Form submitted successfully') || (!isInglish && 'Formulario enviado con éxito'))
                })   

                const toHome = () => {
                    window.removeEventListener("click", toHome);    
                    navigate("/home");
                }

                window.addEventListener("click", toHome)
                                                                       
            } catch (err) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: ((isInglish && 'Error submitting the form, please try again in a few minutes') || (!isInglish && 'Error al enviar el formulario, prueve nuevamente en unos minutos'))
                })   
            }
        }   else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: ((isInglish && 'Missing to enter some data') || (!isInglish && 'Falta ingresar algún dato'))
            })     
        }
    }    
             

    return (
        <div className='contForm flex column'>
            <div className="flex column">
                <div className='contTituloForm flex'><p className='tituloForm'>{(isInglish &&  <>Interest Form</>) || (!isInglish && <>Formulario de Interés</>)}</p></div>
                <form action="" className="formEnrollment">
                    <p className="textosForm">{(isInglish &&  <>Child's Name</>) || (!isInglish && <>Nombre del niño/a</>)} *</p>
                    <input className='input50' name="firstname" type="text" placeholder={(isInglish && "First Name") || (!isInglish && "Nombre")}/>
                    <input className='input50' name="lastname" type="text" placeholder={(isInglish && "Last Name") || (!isInglish && "Apellidos")}/>   <br />
                    
                    <p className="textosForm">{(isInglish &&  <>Child's Date of Birth</>) || (!isInglish && <>Fecha de nacimiento del niño/a</>)} *</p>
                    <input className='input33' name="date1" type="text" placeholder={(isInglish && "MM") || (!isInglish && "DD")} maxLength={2}/>
                    <input className='input33' name="date2" type="text" placeholder={(isInglish && "DD") || (!isInglish && "MM")} maxLength={2}/>
                    <input className='input33' name="year" type="text" placeholder={(isInglish && "YYYY") || (!isInglish && "AAAA")} maxLength={4}/> <br />

                    <p className="textosForm">{(isInglish &&  <>Guardian Name</>) || (!isInglish && <>Nombre familiar 1</>)} *</p>
                    <input className='input100' name="name_lastname1" type="text" /> <br />
                    <p className="textosForm">{(isInglish &&  <>Phone</>) || (!isInglish && <>Número de contacto</>)} *</p>
                    <input className='input100' name="phone1" type="tel" /> <br />
                    <p className="textosForm">{(isInglish &&  <>Email Address</>) || (!isInglish && <>Dirección de correo electrónico</>)} *</p>
                    <input className='input100' name="email1" type="email" /> <br />

                    <p className="textosForm">{(isInglish &&  <>Guardian Name</>) || (!isInglish && <>Nombre familiar 2</>)}</p>
                    <input className='input100' name="name_lastname2" type="text" /> <br />
                    <p className="textosForm">{(isInglish &&  <>Phone</>) || (!isInglish && <>Número de contacto</>)}</p>
                    <input className='input100' name="phone2" type="tel" /> <br />
                    <p className="textosForm">{(isInglish &&  <>Email Address</>) || (!isInglish && <>Dirección de correo electrónico</>)}</p>
                    <input className='input100' name="email2" type="email" /> <br />

                    <div className="contTextosForm">
                        <p className="textosForm">
                            {(isInglish &&  <>Please leave detailed information about your scheduling needs, part-time or full-time program, and tentative start date for
                            classes at our school. Thank you! *</>) 
                            || 
                            (!isInglish && <>Por favor deje información detallada sobre sus necesidades de horarios, programa medio tiempo o tiempo completo,
                            y fecha tentativa de inicio de clases en nuestra escuela. ¡Gracias! *</>)}
                        </p>
                    </div>
                    <textarea name="message" id="" cols="30" rows="10"></textarea>
                    <button className='botonForm' type="button" onClick={sendForm} >{(isInglish &&  <>Send</>) || (!isInglish && <>Enviar</>)}</button>
                </form>
            </div>    
        </div>
    );
}

export default Form;
